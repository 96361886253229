<template>
  <div>
    <heads />
    <div class="banner-src">
      <div class="a">
        <!-- <span>提取码:{{downData.EnglishDownCode}}</span> -->
        <div class="div-button">
          <!-- <a :href="downData.EnglishDownUrl"
            target="_blank"> -->
          <el-button class="button"
            type="primary"
            @click="goListen">
            <span class="span-button-font">播放听力</span>
          </el-button>
          <!-- </a> -->
        </div>
      </div>
    </div>
    <div class="second-banner-src">
      <div class="dataTitle">{{dataTitle}}</div>
      <div class="dataArticle">{{dataArticle}}</div>
      <div class="go-down-style"
        @click="goDown">去下载</div>
      <img :src="groupImg"
        style="margin-top:64px">
    </div>
    <div class="evaluate">
      <div class="evaluate-title">丰富的评价体系</div>
      <div class="evaluate-article">
        <el-row>
          <el-col :span="8">
            <div><img :src="jiangbeiImg"
                class="evaluate-Img">
              <div class="evaluate-heading">理论先进</div>
              <div class="evaluate-details">{{dataArticleOne}}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div><img :src="dingdanImg"
                class="evaluate-Img">
              <div class="evaluate-heading">过程具体</div>
              <div class="evaluate-details">{{dataArticleTwo}}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div><img :src="dianzanImg"
                class="evaluate-Img">
              <div class="evaluate-heading">维度精细</div>
              <div class="evaluate-details">{{dataArticleThree}}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="evaluate1">
      <div class="evaluate-title">优质的试卷</div>
      <div class="evaluate-article">
        <el-row>
          <el-col :span="8">
            <div><img :src="zuanshiImg"
                class="evaluate-Img">
              <div class="evaluate-heading">高质量</div>
              <div class="evaluate-details">{{dataArticleFour}}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div><img :src="mubiaoImg"
                class="evaluate-Img">
              <div class="evaluate-heading">强基础</div>
              <div class="evaluate-details">{{dataArticleFive}}</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div><img :src="jiangpaixunzhangImg"
                class="evaluate-Img">
              <div class="evaluate-heading">重创新</div>
              <div class="evaluate-details">{{dataArticleSix}}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="buttom-div">
      <div class="buttom-div1">
        <!-- <div class="buttom-title">详尽的报告</div> -->
        <!-- <div class="buttomAImg"><img :src="buttomAImg"></div> -->
        <div class="buttomImg" />
      </div>
    </div>
    <div class="flow-div">
      <div class="flowAImg"><img :src="flowAImg"></div>
      <div class="flowBImg"><img :src="flowBImg"></div>
      <div class="flowCImg"><img :src="flowCImg"></div>
      <div class="flowDImg"><img :src="flowDImg"></div>
      <div class="flow-title">使用流程</div>
      <div class="flowImg">
        <el-row type="flex"
          justify="center">
          <el-col :span="2">
            <div class="flow-Img"><img :src="assessmentAImg"></div>
            <div class="flow-article">注册账户</div>
          </el-col>
          <el-col :span="2">
            <div class="flow-Img" />
            <div class="flow-style" />
          </el-col>
          <el-col :span="2">
            <div class="flow-Img"><img :src="assessmentBImg"></div>
            <div class="flow-article">导入学生</div>
          </el-col>
          <el-col :span="2">
            <div class="flow-Img" />
            <div class="flow-style" />
          </el-col>
          <el-col :span="2">
            <div class="flow-Img"><img :src="assessmentCImg"></div>
            <div class="flow-article">组织测评</div>
          </el-col>
          <el-col :span="2">
            <div class="flow-Img" />
            <div class="flow-style" />
          </el-col>
          <el-col :span="2">
            <div class="flow-Img"><img :src="assessmentDImg"></div>
            <div class="flow-article">批改主观题</div>
          </el-col>
          <el-col :span="2">
            <div class="flow-Img" />
            <div class="flow-style" />
          </el-col>
          <el-col :span="2">
            <div class="flow-Img"><img :src="assessmentEImg"></div>
            <div class="flow-article">扫描试卷</div>
          </el-col>
          <el-col :span="2">
            <div class="flow-Img" />
            <div class="flow-style" />
          </el-col>
          <el-col :span="2">
            <div class="flow-Img"><img :src="assessmentFImg"></div>
            <div class="flow-article">查看报告</div>
          </el-col>
        </el-row>
      </div>
      <div>
        <a href="/main">
          <el-button class="free-button">立即使用</el-button>
        </a>
      </div>

    </div>
    <!--  <div class="down-file">
      <div class="other-program-content">
        <div class="others2"
          @mouseover="overothers2"
          @mouseleave="leaveothers2">
          <img :src="sao2"
            class="phoneImg3">
          <div class="others-version">
            <div class="others-name">大数据学科质量测评Windows版</div>
            <div class="others-info">
              <div>适用于Windows 7 及以上版本</div>
              <div>版本：V{{versionPC}}</div>
              <div>大小：{{sizePC}}MB</div>
            </div>
          </div>
          <a :href="downUrlPC"
            :download="downloadWindows.name"
            style="color:#fff;"
            @click="statistics">
            <el-button type="primary"
              class="downClick">下载</el-button>
          </a>
        </div>
        <div class="others"
          @mouseover="overothers"
          @mouseleave="leaveothers">
          <img :src="sao1"
            class="phoneImg3">
          <div class="others-version">
            <div class="others-name">{{downloadOther1.title}}</div>
            <div class="others-info">
              <div>{{downloadOther1.descr}}</div>
              <div>大小：{{downloadOther1.size}}</div>
            </div>
          </div>
          <a :href="downloadOther1.url"
            :download="downloadOther1.name"
            style="color:#fff;">
            <el-button type="primary"
              class="downClick">下载</el-button>
          </a>
        </div>

        <div class="others3"
          @mouseover="overothers3"
          @mouseleave="leaveothers3">
          <img :src="sao3"
            class="phoneImg3">
          <div class="others-version">
            <div class="others-name">{{downloadOther3.title}}</div>
            <div class="others-info">
              <div>{{downloadOther3.descr}}</div>
            </div>
          </div>

          <a :href="downloadOther3.url"
            style="color:#fff;"
            target="blank">
            <el-button type="primary"
              class="downClick">下载</el-button>
          </a>
        </div>
        <div class="others4"
          @mouseover="overothers4"
          @mouseleave="leaveothers4">
          <img :src="sao4"
            class="phoneImg3">
          <div class="others-version">
            <div class="others-name">{{downloadOther4.title}}</div>
            <div class="others-info">
              <div>{{downloadOther4.descr}}</div>
              <div>大小：{{downloadOther4.size}}</div>
            </div>
          </div>
          <a :href="downloadOther4.url"
            :download="downloadOther4.name"
            style="color:#fff;">
            <el-button type="primary"
              class="downClick">下载</el-button>
          </a>
        </div>
      </div>
    </div> -->
    <foots />
    <!-- <contant /> -->
  </div>
</template>
<script>
import { get } from 'util/request';
import _ from 'lodash';
import { dateFormat } from 'util/ui';
import { downPost } from './api';
// import yytl from '../assets/img/assessment/assessmentOne.png';
import group from '../assets/img/assessment/assessmentTwo.png';
import jiangbei from '../assets/img/assessment/assessmentJiangbei.svg';
import dingdan from '../assets/img/assessment/assessmentDingdan.svg';
import dianzan from '../assets/img/assessment/assessmentDianzan.svg';
import zuanshi from '../assets/img/assessment/assessmentZuanshi.svg';
import mubiao from '../assets/img/assessment/assessmentMubiao.svg';
import jiangpaixunzhang from '../assets/img/assessment/assessmentJiangpaixunzhang.svg';
// import assessmentButtom from '../assets/img/assessment/assessmentButtom.jpg';
import assessmentA from '../assets/img/assessment/assessment1.svg';
import assessmentB from '../assets/img/assessment/assessment2.svg';
import assessmentC from '../assets/img/assessment/assessment3.svg';
import assessmentD from '../assets/img/assessment/assessment4.svg';
import assessmentE from '../assets/img/assessment/assessment5.svg';
import assessmentF from '../assets/img/assessment/assessment6.svg';
// import buttomA from '../assets/img/assessment/buttomA.svg';
import flowA from '../assets/img/assessment/flowA.svg';
import flowB from '../assets/img/assessment/flowB.svg';
import flowC from '../assets/img/assessment/flowC.svg';
import flowD from '../assets/img/assessment/flowD.svg';
import heads from './common/head';
import foots from './common/foot';
// import contant from './common/contant';

import sao1 from '../assets/login/sao1.png';
import sao2 from '../assets/login/sao2.png';
import sao3 from '../assets/login/sao3.png';
import sao4 from '../assets/login/sao4.png';

export default {
  components: {
    heads,
    foots,
    // contant,
  },
  data() {
    return {
      sao1,
      sao2,
      sao3,
      sao4,
      downloadWindows: window.config.downloadWindows,
      // 其它版本
      downloadOther1: window.config.downloadOther1,
      downloadOther2: window.config.downloadOther2,
      downloadOther3: window.config.downloadOther3,
      downloadOther4: window.config.downloadOther4,
      downUrlPC: '',
      versionPC: '',
      sizePC: '',
      // yytlImg: yytl,
      groupImg: group,
      jiangbeiImg: jiangbei,
      dingdanImg: dingdan,
      dianzanImg: dianzan,
      zuanshiImg: zuanshi,
      mubiaoImg: mubiao,
      jiangpaixunzhangImg: jiangpaixunzhang,
      // assessmentButtomImg: assessmentButtom,
      assessmentAImg: assessmentA,
      assessmentBImg: assessmentB,
      assessmentCImg: assessmentC,
      assessmentDImg: assessmentD,
      assessmentEImg: assessmentE,
      assessmentFImg: assessmentF,
      // buttomAImg: buttomA,
      flowAImg: flowA,
      flowBImg: flowB,
      flowCImg: flowC,
      flowDImg: flowD,
      dataTitle: '大数据学科质量测评',
      dataArticle:
        '大数据河南工程实验室牵头命制，适用于河南本省的学业质量标准。 通过大数据工具进行学情诊断分析，推动学习行为的改进和教学策略的优化，实现对基于数据的课堂教学和个性化学习的支持',
      dataArticleOne:
        '采用高校的课程论、教学论及专家团队多年研究的教学质量测评理论，结合研究成果在上海等发达地区的应用经验，全面提升基础教育质量',
      dataArticleTwo: '既能够通览全局对班级、学生进行整体评价，也能够针对具体章节、课时、知识点进行评价',
      dataArticleThree: '评价的维度从单一的知识要求层级扩展到问题解答能力水平，评价维度更精细',
      dataArticleFour:
        '由郑州、洛阳、开封、三门峡、南阳、焦作等多个地市的优秀教研员和一线名师150多名，经过培训研讨，精心编写',
      dataArticleFive: '测试为水平性测试，考虑到地域性以及城乡教育水平的差异，试卷的难度系数控制在0.7-0.8之间',
      dataArticleSix:
        '关注学生学科素养，学科文化的教育走向。在遵循教学规律和学生认知规律的基础上，力求逐步在试题格式、内容、选材上做到守正创新',
      downData: window.config,
    };
  },
  created() {
    const num = _.parseInt(Math.random(0, 1) * 1000);
    get(`${this.downloadWindows.urlinfo}?${num}`).then(({ data, err }) => {
      if (!err) {
        const { version, size, date } = data;
        this.versionPC = version;
        this.sizePC = (size / 1024 / 1024).toFixed(1);
        this.updateTimePC = dateFormat(date, 'YYYY-MM-DD');
        this.downUrlPC = `${this.downloadWindows.downUrl}${version}/${this.downloadWindows.name}`;
      }
    });
  },
  methods: {
    goDown() {
      this.$router.push('/download');
    },
    /* 统计下载次数 */
    statistics() {
      downPost({ type: 'szjcyyy' });
    },
    goListen() {
      this.$router.push('hearing');
    },
    overothers() {
      const others = document.querySelector('.others');
      others.style.border = '1px solid #1890FF';
    },
    leaveothers() {
      const others = document.querySelector('.others');
      others.style.border = '1px solid #E8E8E8';
    },
    overothers2() {
      const others = document.querySelector('.others2');
      others.style.border = '1px solid #1890FF';
    },
    leaveothers2() {
      const others = document.querySelector('.others2');
      others.style.border = '1px solid #E8E8E8';
    },
    overothers3() {
      const others = document.querySelector('.others3');
      others.style.border = '1px solid #1890FF';
    },
    leaveothers3() {
      const others = document.querySelector('.others3');
      others.style.border = '1px solid #E8E8E8';
    },
    overothers4() {
      const others = document.querySelector('.others4');
      others.style.border = '1px solid #1890FF';
    },
    leaveothers4() {
      const others = document.querySelector('.others4');
      others.style.border = '1px solid #E8E8E8';
    },
  },
};
</script>

<style scope lang="less">
.banner-src {
  background: url(../assets/img/assessment/assessmentOne2022.jpg) center;
  margin-top: 303px;
  text-align: center;
  width: 100%;
  height: 500px;
  .img {
    width: 100%;
    height: 500px;
  }
  .a {
    margin-top: -160px;
    padding-top: 280px;
    font-size: 24px;
    color: rgba(0, 99, 76, 1);
  }
  .div-button {
    margin-top: 60px;
    .button {
      width: 220px;
      height: 60px;
      background: linear-gradient(180deg, rgba(250, 217, 97, 1) 0%, rgba(247, 107, 28, 1) 100%);
      border-radius: 30px;
      border: none;
    }
    .span-button-font {
      font-size: 24px;
      color: rgba(255, 255, 255, 1);
    }
  }
}
.second-banner-src {
  margin-top: 64px;
  text-align: center;
  .dataTitle {
    margin: 0 auto;
    width: 700px;
    font-size: 42px;
    color: rgba(38, 38, 38, 1);
  }
  .dataArticle {
    padding-top: 18px;
    margin: 0 auto;
    width: 723px;
    font-size: 16px;
    color: rgba(89, 89, 89, 1);
  }
  .go-down-style {
    width: 200px;
    height: 64px;
    background: #1890ff;
    border-radius: 32px;
    margin: auto;
    margin-top: 32px;
    text-align: center;

    font-size: 24px;
    font-family: PingFangSC, PingFangSC-Medium;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 64px;
    cursor: pointer;
  }
}
.evaluate {
  width: 100%;
  height: 600px;
  background: rgba(250, 250, 250, 1);
  margin-top: 56px;
  text-align: center;
  .evaluate-title {
    padding-top: 104px;
    font-size: 42px;
    color: rgba(38, 38, 38, 1);
  }
  .evaluate-article {
    margin: 64px auto;
    width: 1210px;
    .evaluate-Img {
      width: 120px;
      height: 120px;
    }
    .evaluate-details {
      margin: 0 auto;
      margin-top: 22px;
      width: 347px;
      font-size: 16px;
      color: rgba(89, 89, 89, 1);
    }
    .evaluate-heading {
      margin-top: 27px;
      font-size: 26px;
      color: rgba(38, 38, 38, 1);
    }
  }
}
.evaluate1 {
  width: 100%;
  height: 600px;
  margin-top: 80px;
  text-align: center;
  .evaluate-title {
    font-size: 42px;
    color: rgba(38, 38, 38, 1);
  }
  .evaluate-article {
    margin: 64px auto;
    width: 1210px;
    .evaluate-Img {
      width: 120px;
      height: 120px;
    }
    .evaluate-details {
      margin: 0 auto;
      margin-top: 22px;
      width: 347px;
      font-size: 16px;
      color: rgba(89, 89, 89, 1);
    }
    .evaluate-heading {
      margin-top: 42px;
      font-size: 26px;
      color: rgba(38, 38, 38, 1);
    }
  }
}
.buttom-div {
  width: 100%;
  height: 800px;
  // background: rgba(0, 67, 209, 1);
  text-align: center;
  .buttom-title {
    font-size: 42px;
    color: rgba(255, 255, 255, 1);
    padding-top: 45px;
  }
  // .buttomAImg {
  //   margin-top: 17px;
  // }
}
.buttom-div1 {
  position: relative;
  // margin: auto;
  width: 100%;
  height: 800px;
}
.buttomImg {
  background: url('../assets/img/assessment/assessmentButtom.jpg') center;
  position: absolute;
  width: 100%;
  height: 800px;
  left: 0;
  bottom: 0;
}
.flow-div {
  width: 100%;
  height: 540px;
  background: rgba(24, 144, 255, 1);
  text-align: center;
  .flowAImg {
    width: 143px;
    height: 151px;
    position: absolute;
    margin-left: 1659px;
  }
  .flowBImg {
    width: 143px;
    height: 151px;
    position: absolute;
    margin-top: 340px;
    margin-left: 33px;
  }
  .flowCImg {
    width: 143px;
    height: 151px;
    position: absolute;
  }
  .flowDImg {
    width: 143px;
    height: 151px;
    position: absolute;
    margin-top: 356px;
    margin-left: 1534px;
  }
  .flow-title {
    font-size: 48px;
    color: rgba(255, 255, 255, 1);
    padding-top: 63px;
  }
  .flowImg {
    margin: 72px auto;
    width: 1210px;
    .flow-article {
      font-size: 20px;
      color: rgba(255, 255, 255, 1);
    }
    .flow-style {
      margin-top: 78px;
      width: 80px;
      height: 1px;
      background: linear-gradient(90deg, rgba(24, 144, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
    }
  }
  .free-button {
    width: 180px;
    height: 50px;
    background: rgba(255, 255, 255, 1);
    color: rgba(24, 144, 255, 1);
    font-size: 20px;
    border-radius: 25px;
  }
}
.down-file {
  width: 1200px;
  // height: 45px;
  font-size: 32px;
  margin: auto;
  margin-top: 90px;
  .phoneImg3 {
    width: 110px;
    height: 110px;
    margin-left: 25px;
    margin-right: 41px;
    margin-top: 25px;
    float: left;
  }
  .downClick {
    width: 88px;
    height: 32px;
    margin-left: 46px;
    margin-top: 64px;
  }
  .other-program-content {
    width: 1200px;
    height: 360px;
    margin: auto;
    margin-top: 48px;
    margin-bottom: 120px;
  }
  .others {
    width: 578px;
    height: 158px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #e8e8e8;
    float: left;
    margin-left: 40px;
  }
  .others2 {
    width: 578px;
    height: 158px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #e8e8e8;
    float: left;
  }
  .others3 {
    width: 578px;
    height: 158px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #e8e8e8;
    float: left;
    margin-top: 40px;
  }
  .others4 {
    width: 578px;
    height: 158px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #e8e8e8;
    float: left;
    margin-top: 40px;
    margin-left: 40px;
  }
  .others:hover,
  .others2:hover,
  .others3:hover,
  .others4:hover {
    cursor: pointer;
  }
  .others-version {
    margin-left: 61px;
    float: left;
    margin-top: 37px;
  }
  .others-name {
    width: 186px;
    height: 22px;
    font-size: 16px;
  }
  .others-info {
    margin-top: 20px;
    font-size: 14px;
    color: #9b9b9b;
  }
}
</style>
