var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("heads"),
      _c("div", { staticClass: "banner-src" }, [
        _c("div", { staticClass: "a" }, [
          _c(
            "div",
            { staticClass: "div-button" },
            [
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "primary" },
                  on: { click: _vm.goListen }
                },
                [
                  _c("span", { staticClass: "span-button-font" }, [
                    _vm._v("播放听力")
                  ])
                ]
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "second-banner-src" }, [
        _c("div", { staticClass: "dataTitle" }, [
          _vm._v(_vm._s(_vm.dataTitle))
        ]),
        _c("div", { staticClass: "dataArticle" }, [
          _vm._v(_vm._s(_vm.dataArticle))
        ]),
        _c("div", { staticClass: "go-down-style", on: { click: _vm.goDown } }, [
          _vm._v("去下载")
        ]),
        _c("img", {
          staticStyle: { "margin-top": "64px" },
          attrs: { src: _vm.groupImg }
        })
      ]),
      _c("div", { staticClass: "evaluate" }, [
        _c("div", { staticClass: "evaluate-title" }, [
          _vm._v("丰富的评价体系")
        ]),
        _c(
          "div",
          { staticClass: "evaluate-article" },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", [
                    _c("img", {
                      staticClass: "evaluate-Img",
                      attrs: { src: _vm.jiangbeiImg }
                    }),
                    _c("div", { staticClass: "evaluate-heading" }, [
                      _vm._v("理论先进")
                    ]),
                    _c("div", { staticClass: "evaluate-details" }, [
                      _vm._v(_vm._s(_vm.dataArticleOne))
                    ])
                  ])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", [
                    _c("img", {
                      staticClass: "evaluate-Img",
                      attrs: { src: _vm.dingdanImg }
                    }),
                    _c("div", { staticClass: "evaluate-heading" }, [
                      _vm._v("过程具体")
                    ]),
                    _c("div", { staticClass: "evaluate-details" }, [
                      _vm._v(_vm._s(_vm.dataArticleTwo))
                    ])
                  ])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", [
                    _c("img", {
                      staticClass: "evaluate-Img",
                      attrs: { src: _vm.dianzanImg }
                    }),
                    _c("div", { staticClass: "evaluate-heading" }, [
                      _vm._v("维度精细")
                    ]),
                    _c("div", { staticClass: "evaluate-details" }, [
                      _vm._v(_vm._s(_vm.dataArticleThree))
                    ])
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "evaluate1" }, [
        _c("div", { staticClass: "evaluate-title" }, [_vm._v("优质的试卷")]),
        _c(
          "div",
          { staticClass: "evaluate-article" },
          [
            _c(
              "el-row",
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", [
                    _c("img", {
                      staticClass: "evaluate-Img",
                      attrs: { src: _vm.zuanshiImg }
                    }),
                    _c("div", { staticClass: "evaluate-heading" }, [
                      _vm._v("高质量")
                    ]),
                    _c("div", { staticClass: "evaluate-details" }, [
                      _vm._v(_vm._s(_vm.dataArticleFour))
                    ])
                  ])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", [
                    _c("img", {
                      staticClass: "evaluate-Img",
                      attrs: { src: _vm.mubiaoImg }
                    }),
                    _c("div", { staticClass: "evaluate-heading" }, [
                      _vm._v("强基础")
                    ]),
                    _c("div", { staticClass: "evaluate-details" }, [
                      _vm._v(_vm._s(_vm.dataArticleFive))
                    ])
                  ])
                ]),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", [
                    _c("img", {
                      staticClass: "evaluate-Img",
                      attrs: { src: _vm.jiangpaixunzhangImg }
                    }),
                    _c("div", { staticClass: "evaluate-heading" }, [
                      _vm._v("重创新")
                    ]),
                    _c("div", { staticClass: "evaluate-details" }, [
                      _vm._v(_vm._s(_vm.dataArticleSix))
                    ])
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._m(0),
      _c("div", { staticClass: "flow-div" }, [
        _c("div", { staticClass: "flowAImg" }, [
          _c("img", { attrs: { src: _vm.flowAImg } })
        ]),
        _c("div", { staticClass: "flowBImg" }, [
          _c("img", { attrs: { src: _vm.flowBImg } })
        ]),
        _c("div", { staticClass: "flowCImg" }, [
          _c("img", { attrs: { src: _vm.flowCImg } })
        ]),
        _c("div", { staticClass: "flowDImg" }, [
          _c("img", { attrs: { src: _vm.flowDImg } })
        ]),
        _c("div", { staticClass: "flow-title" }, [_vm._v("使用流程")]),
        _c(
          "div",
          { staticClass: "flowImg" },
          [
            _c(
              "el-row",
              { attrs: { type: "flex", justify: "center" } },
              [
                _c("el-col", { attrs: { span: 2 } }, [
                  _c("div", { staticClass: "flow-Img" }, [
                    _c("img", { attrs: { src: _vm.assessmentAImg } })
                  ]),
                  _c("div", { staticClass: "flow-article" }, [
                    _vm._v("注册账户")
                  ])
                ]),
                _c("el-col", { attrs: { span: 2 } }, [
                  _c("div", { staticClass: "flow-Img" }),
                  _c("div", { staticClass: "flow-style" })
                ]),
                _c("el-col", { attrs: { span: 2 } }, [
                  _c("div", { staticClass: "flow-Img" }, [
                    _c("img", { attrs: { src: _vm.assessmentBImg } })
                  ]),
                  _c("div", { staticClass: "flow-article" }, [
                    _vm._v("导入学生")
                  ])
                ]),
                _c("el-col", { attrs: { span: 2 } }, [
                  _c("div", { staticClass: "flow-Img" }),
                  _c("div", { staticClass: "flow-style" })
                ]),
                _c("el-col", { attrs: { span: 2 } }, [
                  _c("div", { staticClass: "flow-Img" }, [
                    _c("img", { attrs: { src: _vm.assessmentCImg } })
                  ]),
                  _c("div", { staticClass: "flow-article" }, [
                    _vm._v("组织测评")
                  ])
                ]),
                _c("el-col", { attrs: { span: 2 } }, [
                  _c("div", { staticClass: "flow-Img" }),
                  _c("div", { staticClass: "flow-style" })
                ]),
                _c("el-col", { attrs: { span: 2 } }, [
                  _c("div", { staticClass: "flow-Img" }, [
                    _c("img", { attrs: { src: _vm.assessmentDImg } })
                  ]),
                  _c("div", { staticClass: "flow-article" }, [
                    _vm._v("批改主观题")
                  ])
                ]),
                _c("el-col", { attrs: { span: 2 } }, [
                  _c("div", { staticClass: "flow-Img" }),
                  _c("div", { staticClass: "flow-style" })
                ]),
                _c("el-col", { attrs: { span: 2 } }, [
                  _c("div", { staticClass: "flow-Img" }, [
                    _c("img", { attrs: { src: _vm.assessmentEImg } })
                  ]),
                  _c("div", { staticClass: "flow-article" }, [
                    _vm._v("扫描试卷")
                  ])
                ]),
                _c("el-col", { attrs: { span: 2 } }, [
                  _c("div", { staticClass: "flow-Img" }),
                  _c("div", { staticClass: "flow-style" })
                ]),
                _c("el-col", { attrs: { span: 2 } }, [
                  _c("div", { staticClass: "flow-Img" }, [
                    _c("img", { attrs: { src: _vm.assessmentFImg } })
                  ]),
                  _c("div", { staticClass: "flow-article" }, [
                    _vm._v("查看报告")
                  ])
                ])
              ],
              1
            )
          ],
          1
        ),
        _c("div", [
          _c(
            "a",
            { attrs: { href: "/main" } },
            [
              _c("el-button", { staticClass: "free-button" }, [
                _vm._v("立即使用")
              ])
            ],
            1
          )
        ])
      ]),
      _c("foots")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "buttom-div" }, [
      _c("div", { staticClass: "buttom-div1" }, [
        _c("div", { staticClass: "buttomImg" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }